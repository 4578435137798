/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const howToWork = css`
  background-color: ${colors.gray};
  padding: 120px 0px;

  h2 {
    font-size: 48px;
    margin-bottom: 32px;
  }

  p {
    text-align: left;
    margin-bottom: 32px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 60px 0px 0;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }

  img {
    width: 100%;
  }

  ul {
    display: flex;
    margin-bottom: 64px;
    li {
      display: inline-block;
      width: 33.3%;
      padding: 0 20px;

      &:nth-child(2) {
        div img {
          width: 100px;
        }
      }

      &:nth-child(3) {
        div img {
          width: 81px;
        }
      }
      div {
        text-align: center;
        background-color: ${colors.green};
        color: #ffffff;
        border-radius: 12px;
        border: 3px solid #c7e3ba;
        height: 192px;
        position: relative;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: 0.3s;
        img {
          width: 124px;
          margin-bottom: 10px;
          transition: 0.3s;
        }
        span {
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          margin: 0;
          transition: 0.3s;
        }

        &:hover {
          background-color: ${colors.yellow};
          border-color: #fdf2b4;
          span {
            color: #000000;
          }
          img {
            filter: brightness(0);
          }
        }
      }
    }
  }
`;

export const faseCard = css`
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 32px;

  @media (min-width: 320px) and (max-width: 767px) {
    width: 300px;
    margin: 32px auto;
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const faseCard2 = css`
  background-color: #F1F2F6;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 32px;

  @media (min-width: 320px) and (max-width: 767px) {
    width: 300px;
    margin: 32px auto;
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const fase2 = css`
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    margin-bottom: 32px;
  }
  p {
    text-align: center !important;
    font-size: 18px;
    line-height: 22px;
    height: 110px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
    img {
      margin: 30px 0;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const faseDate = css`
  background-color: ${colors.blue};
  text-align: center;
  padding: 4px 0;
  span {
    color: #ffffff;
    font-size: 18px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    span {
      line-height: 22px;
      width: 73%;
      display: block;
      margin: 0 auto;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const faseText = css`
  padding: 16px;
`;

export const fase = css`
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  img {
    width: 50%;
    margin-right: 16px;
  }
  p {
    text-align: left;
    font-size: 18px;
    line-height: 22px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
    img {
      margin: 30px 0;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const imgFases = css`
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 1;
  width: 58%;
  height: 100%;

  img {
    position: absolute;
    bottom: 0;
    width: 90%;
    left: 25px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    position: relative;
    width: 100%;
    img {
      position: relative;
      width: 100%;
      left: 0;
      margin-top: 60px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;

export const tag = css`
  display: flex;
  align-items: center;
  img {
    width: 128px;
    -webkit-filter: drop-shadow(-9px 8px 8px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(-9px 8px 8px rgba(0, 0, 0, 0.2));
  }
  p {
    font-size: 18px;
    line-height: 27px;
    padding: 0px 38px;
    margin: 0;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    img {
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`;
