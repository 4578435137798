import logoDefault from "@/images/logo.svg"
import logoFooter from "@/images/footer/logoFooter.svg"
import professor1 from "@/images/topo/teacher1.png"
import professor2 from "@/images/topo/teacher2.png"
import profMobile from "@/images/topo/prof_mobile.png"
import bracketL from "@/images/blockquote/bracketL.svg"
import blockQuote from "@/images/blockquote/blockquote.svg"
import smallBQ from "@/images/blockquote/smallBlockquote.svg"
import imgTable from "@/images/how/pisa2018.png"
import livros from "@/images/newsletter/livros.png"
import leftWhiteBracket from "@/images/newsletter/lwb.svg"
import rightWhiteBracket from "@/images/newsletter/rwb.svg"
import icon1 from "@/images/who/icon1.svg"
import icon2 from "@/images/who/icon2.svg"
import icon3 from "@/images/who/icon3.svg"
import v1 from "@/images/topo/v1.svg"
import v2 from "@/images/topo/v2.svg"
import v3 from "@/images/topo/v3.svg"
import fase1 from "@/images/howtowork/fase1.svg"
import fase2 from "@/images/howtowork/fase2.svg"
import fase3 from "@/images/howtowork/fase3.svg"
import professor3 from "@/images/howtowork/prof3.png"
import tag from "@/images/howtowork/tag.svg"
import imgPartners from "@/images/partner/partners.png"
import sliderOne01 from "@/images/slider/1_1.jpg"
import sliderOne02 from "@/images/slider/1_2.jpg"
import sliderTwo01 from "@/images/slider/2_1.jpg"
import sliderTwo02 from "@/images/slider/2_2.jpg"
import sliderTwo03 from "@/images/slider/2_3.jpg"
import sliderThree01 from "@/images/slider/3_1.jpg"
import sliderThree02 from "@/images/slider/3_2.jpg"
import blogImage1 from "@/images/blog/1.jpg"
import blogImage2 from "@/images/blog/2.jpg"
import blogImage3 from "@/images/blog/3.jpg"
import blogImage4 from "@/images/blog/4.jpg"
import blogImage5 from "@/images/blog/5.jpg"
import blogImage6 from "@/images/blog/6.jpg"
import blogImageTwo1 from "@/images/blog/14.jpg"
import blogImageTwo2 from "@/images/blog/15.jpg"
import blogS1 from "@/images/blog/11.jpg"
import blogS2 from "@/images/blog/12.jpg"
import blogS3 from "@/images/blog/13.jpg"
import commentImage1 from "@/images/blog/9.jpg"
import commentImage2 from "@/images/blog/10.jpg"
import portfolio01 from "@/images/portfolio/1.jpg"
import portfolio02 from "@/images/portfolio/2.jpg"
import portfolio03 from "@/images/portfolio/3.jpg"
import portfolio04 from "@/images/portfolio/4.jpg"
import portfolio05 from "@/images/portfolio/5.jpg"
import portfolio06 from "@/images/portfolio/6.jpg"
import portfolioD01 from "@/images/portfolio/7.jpg"
import portfolioD02 from "@/images/portfolio/8.jpg"
import serviceOne01 from "@/images/home_1/6.jpg"
import serviceOne02 from "@/images/home_1/7.jpg"
import serviceOne03 from "@/images/home_1/8.jpg"
import trustClient01 from "@/images/home_1/4.jpg"
import ClientCarousel01 from "@/images/client/1.png"
import ClientCarousel02 from "@/images/client/2.png"
import ClientCarousel03 from "@/images/client/3.png"
import ClientCarousel04 from "@/images/client/4.png"
import ClientCarousel05 from "@/images/client/5.png"
import aboutOne01 from "@/images/about/1.jpg"
import aboutOne02 from "@/images/about/2.jpg"
import quemSomos from "@/images/about/quemSomos.png"
import team01 from "@/images/team/1.jpg"
import team02 from "@/images/team/2.jpg"
import team03 from "@/images/team/3.jpg"
import team04 from "@/images/team/4.jpg"
import team05 from "@/images/team/5.jpg"
import video01 from "@/images/about/3.jpg"
import testimonial01 from "@/images/home_1/t1.jpg"
import testimonial02 from "@/images/home_1/t2.jpg"
import testimonial03 from "@/images/home_1/t3.jpg"
import featureTab01 from "@/images/home_1/c1.jpg"
import featureTab02 from "@/images/home_1/c2.jpg"
import featureTab03 from "@/images/home_1/c3.jpg"
import video02 from "@/images/home_1/3.jpg"
import aboutTwo01 from "@/images/home_1/2.jpg"
import aboutTwo02 from "@/images/home_1/1.jpg"
import featureTwo01 from "@/images/home_1/5.jpg"
import vertereLogo from "@/images/footer/vertere.svg"
import fractalLogo from "@/images/footer/fractal_vertical.svg"
import fractalH from "@/images/footer/fractal_horizontal.svg"
import minLogo from "@/images/logos/RODAPE_MCTI.jpeg"
import emap from "@/images/logos/EMAp.png"
import ita from "@/images/logos/ITA_logo.png"
import itaex from "@/images/logos/itaex-amarelo.png"
import funcamp from "@/images/logos/logo_funcamp.png"
import imecc from "@/images/logos/logo-imecc-513x450.png"
import sbm from "@/images/logos/SBM_logo-01.png"
import ufc from "@/images/logos/UFC_brasao1_horizontal_cor_72dpi.png"
import embaixada from "@/images/logos/logo-embaixada.png"
import mec from "@/images/logos/logo-mec.png"
import ANPMAT from "@/images/logos/logo_ANPMAT.jpg"
import lenovo from "@/images/logos/logo-lenovo-foundation.jpg"
import aegea from "@/images/logos/Aegea1.png"
import lenovoF from "@/images/logos/Lenovo_Foundation_POS_Red_H_767x511 1.png"
import primeiraEscolha from "@/images/logos/logo-primeiraescolha-azul.png"
import yellowArrow from "@/images/yellow_arrow.svg"
import prof1 from "@/images/profs/professor-1.png"
import prof2 from "@/images/profs/professor-2.png"
import prof3 from "@/images/profs/professor-3.png"
import prof4 from "@/images/profs/professor-4.png"
import prof5 from "@/images/profs/professor-5.png"
import prof6 from "@/images/profs/professor-6.png"
import prof7 from "@/images/profs/professor-7.png"
import prof8 from "@/images/profs/professor-8.png"
import prof9 from "@/images/profs/professor-9.jpg"
import prof10 from "@/images/profs/professor-10.jpg"
import prof11 from "@/images/profs/professor-11.png"

export const LogoImage = {
  light: logoDefault,
};

export const BlockquoteData = {
    vector: bracketL,
    blockquote: blockQuote, 
    smallBlockQuote: smallBQ,
    title: "Selecionaremos 10 professores de matemática que estão fazendo a diferença para serem premiados.",
    desc: 'O objetivo é  selecionar, anualmente, até 10 Professores de Matemática que fazem a diferença para os alunos, os quais serão premiados com uma viagem para conhecer pessoalmente o sistema educacional de um dos países classificados entre os 10 melhores em Matemática no ranking do PISA – "Olimpíada de Professores de Matemática do Ensino Médio"',
  }
;

export const HowData = {
  image: imgTable,
};

export const WhoData = [
  {
    icon: icon1,
    title: "Professores do \n Ensino Médio"
  },
  {
    icon: icon2,
    title: "Todas as idades"
  },
  {
    icon: icon3,
    title: "Atuando na educação \n de matemática"
  },
];

export const ConselhoData = [
  {
    img: prof1,
    name: "Cristovam Buarque",
    desc: "Senador e Ministro,\n criador do Bolsa Escola."
  },
  {
    img: prof2,
    name: "Fernanda Pereira",
    desc: "Chefe Depto.\n Matemática ITA."
  },
  {
    img: prof3,
    name: "Veveu Arruda",
    desc: "Ex-Prefeito de Sobral \n Diretor Executivo da \n Associação Bem Comum."
  },
  {
    img: prof4,
    name: "Jorge Lira",
    desc: "Professor da UFC e membro do Conselho Diretor da SBM"
  },
  {
    img: prof5,
    name: "Liliana Kffuri",
    desc: "Diretora SEED/PR"
  },
  {
    img: prof6,
    name: "Eduardo Wagner",
    desc: "Professor EMAp/FGV"
  },
  {
    img: prof7,
    name: "Paulo Ruffino",
    desc: "Diretor IMECC UNICAMP"
  },
  {
    img: prof8,
    name: "Daniel Lavouras",
    desc: "Especialista em Olimpíadas de Conhecimento"
  },
  {
    img: prof9,
    name: "Denise Pires de Carvalho",
    desc: "Secretária de Educação, Superior (SESU), MEC"
  },
  {
    img: prof10,
    name: "Katia Schweickardt",
    desc: "Secretária de Educação, Básica (SEB), MEC"
  },
  {
    img: prof11,
    name: "Marcela Souza",
    desc: "Presidente da ANPMat"
  },
];

export const HowToWorkData = [
  {
    image: fase1,
    text: "Será composta de um questionário e de uma avaliação didática",
    date: "Esta fase será realizada em Junho de 2025",
  },
  {
    image: fase2,
    text: "A avaliação será feita através de um questionário sobre os métodos usados pelo professor em sala de aula e fora dela, bem como vídeos demonstrativos. \n O objetivo é identificar melhores práticas no ensino de Matemática no ensino médio no Brasil e no mundo. \n Os premiados irão compartilhar o aprendizado para melhorar a Educação no Brasil. ",
    date: "Esta fase será realizada em Agosto de 2025",
  },
  {
    image: fase3,
    text: "Será por entrevista com o Comitê Acadêmico.",
    date: "Esta fase será realizada em Setembro 2025",
  },
];

export const ExecutivoData = [
  {
    name: "Adauto Caldara",
  },
  {
    name: "Alan do Amaral Fernandes",
  },
  {
    name: "Cícero Crispim",
  },
  {
    name: "Eidi Fujimaki",
  },
  {
    name: "Euben Monteiro",
  },
  {
    name: "Francisco Kommisar del Campo",
  },
  {
    name: "Hitoshi Nagano",
  },
  {
    name: "José Antônio Puppim de Oliveira",
  },
  {
    name: "José Henrique Arantes Soares",
  },
  {
    name: "Marcelo Giovanni Pache",
  },
  {
    name: "Maurício Etsuo Endo",
  },
  {
    name: "Raílson Guilherme de Castro",
  },
  {
    name: "João Valberto Cavalcanti ",
  },
  {
    name: "Wilson Serbena",
  },

];

export const HowToWorkImage = {
    img1: professor3,
    img2: tag,
}

export const SectionImage = {
  img: yellowArrow,
}

export const SupportImage = {
  vertere: vertereLogo,
  fractalVertical: fractalLogo,
  ministeiro: minLogo,
  logo: logoFooter,
  fractalHorizontal: fractalH,
  emap: emap,
  ita : ita,
  itaex : itaex,
  funcamp : funcamp,
  imecc : imecc,
  sbm: sbm,
  ufc : ufc, 
  mec: mec,
  embaixada : embaixada, 
  ANPMAT : ANPMAT, 
  lenovo: lenovo,
  aegea: aegea,
  lenovoF: lenovoF,
  primeiraEscolha: primeiraEscolha,
};

export const PartnersImage = {
  img1: imgPartners,
};

export const NavLinks = [
  {
    name: "Quem Somos",
    url: "/quem-somos",
  },
  {
    name: "Para quem é?",
    url: "#quem",
  },
  {
    name: "Como funciona?",
    url: "#como",
  },
  {
    name: "Temáticas",
    url: "#tematicas",
  },
  {
    name: "Como estudar?",
    url: "#como_estudar",
  },
];

export const NavInnerLinks = [
  {
    name: "Quem Somos",
    url: "/quem-somos",
  },
  {
    name: "Para quem é?",
    url: "/#quem",
  },
  {
    name: "Como funciona?",
    url: "/#como",
  },
  {
    name: "Temáticas",
    url: "/#tematicas",
  },
  {
    name: "Como estudar?",
    url: "/#como_estudar",
  },
];



export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM IPSUM \n DOLOR SIT.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM IPSUM \n DOLOR SIT.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
];




export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM\nIPSUM\nDOLOR.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
  {
    image: sliderTwo02,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM\nIPSUM\nDOLOR.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
  {
    image: sliderTwo03,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM\nIPSUM\nDOLOR.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
];


export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "LOREM IPSUM",
  title: "Lorem ipsum dolor",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const ContactInfosList = [
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "lorem",
    infos: [
      {
        name: "Lorem ipsum dolor sit",
      },
      {
        name: "lorem@ipsum.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Lorem ipsum dolor",
  title: "Lorem ipsum dolor sit",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};


export const BlogData = [
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];


export const BlogTwoData = [
  {
    title:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "Lorem ipsum dolor sit amet,",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "Lorem ipsum",
  title: "Lorem ipsum dolor",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};


export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];


export const BlogComments = [
  {
    image: commentImage1,
    name: "Lorem Ipsum",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Lorem Ipsum",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];


export const PortfolioData = [
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "LOREM IPSUM",
    categories: ["all", "graphic"],
    image: portfolio06,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "LOREM IPSUM",
  text:
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris.",
  client: "Lorem ipsum",
  categories: [
    {
      name: "Lorem,",
      url: "#",
    },
    {
      name: "Lorem,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Lorem Ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    iconName: "mei-settings",
    url: "/service-details",
  },
  {
    title: "Lorem Ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    iconName: "mei-transfer",
    url: "/service-details",
  },
  {
    title: "Lorem Ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  },
];

export const ServiceHomeTwoData = {
  subTitle: "Lorem ipsum dolor sit amet",
  title: "Lorem ipsum dolor sit amet, \n consectetur adipiscing elit.",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const ServiceHomeThreeData = {
  subTitle: "Lorem ipsum dolor sit",
  title: "Lorem ipsum",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, \n consectetur adipiscing elit.",
    subTitle: "Lorem ipsum dolor sit",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      title: "Lorem ipsum",
      iconName: "mei-web-design",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-computer-graphic",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-development-1",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-development",
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      iconName: "mei-app-development",
      url: "/service-details",
    },
  ],
};

export const ServicePostThreeData = {
  sectionContent: {
    title: "Lorem ipsum dolor",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      title: "Lorem ipsum",
      image: serviceOne01,
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      image: serviceOne02,
      url: "/service-details",
    },
    {
      title: "Lorem ipsum",
      image: serviceOne03,
      url: "/service-details",
    },
  ],
};

export const FunfactData = [
  {
    title: "Lorem ipsum",
    countNumber: 705,
  },
  {
    title: "Lorem ipsum",
    countNumber: 480,
  },
  {
    title: "Lorem ipsum",
    countNumber: 626,
  },
  {
    title: "Lorem ipsum",
    countNumber: 774,
  },
];

export const TrustClientData = {
  image: trustClient01,
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

export const ClientCarouselData = {
  sectionContent: {
    title: "Lorem ipsum dolor",
    subTitle: "Lorem ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
  ],
};

export const AboutOneData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
    subTitle: "Lorem ipsum dolor",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Lorem ipsum",
    number: 1990,
  },
};

export const TeamOneData = {
  sectionContent: {
    title: "Lorem ipsum dolor",
    subTitle: "Lorem ipsum dolor dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      image: team01,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Lorem ipsum",
      designation: "Lorem ipsum",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

export const VideoOneData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Lorem ipsum ",
    subTitle: "Lorem ipsum dolor sit amet",
  },
};

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "Lorem ipsum",
    title: "Lorem ipsum",
  },
  posts: [
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial01,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial02,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial03,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial01,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial02,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial03,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial01,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial02,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
    {
      name: "Lorem ipsum",
      designation: "Lorem",
      image: testimonial03,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex.",
      date: "25 nov, 2018",
    },
  ],
};

export const FeatureTabData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  posts: [
    {
      title: "Lorem ipsum",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex. Curabitur aliquet nec tortor eget blandit. Sed consectetur pellentesque aliquam. Fusce id malesuada sapien. Suspendisse vel vulputate libero. ",
      image: featureTab01,
      lists: [
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
      ],
    },
    {
      title: "Lorem ipsum",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex. Curabitur aliquet nec tortor eget blandit. Sed consectetur pellentesque aliquam. Fusce id malesuada sapien. Suspendisse vel vulputate libero. ",
      image: featureTab02,
      lists: [
        {
          item: "SLorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
      ],
    },
    {
      title: "Lorem ipsum dolor",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris. Nunc sed ipsum malesuada, lacinia risus vitae, blandit ex. Curabitur aliquet nec tortor eget blandit. Sed consectetur pellentesque aliquam. Fusce id malesuada sapien. Suspendisse vel vulputate libero. ",
      image: featureTab03,
      lists: [
        {
          item: "SLorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
        {
          item: "Lorem ipsum dolor sit amet, consectetur",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  specialText: "Lorem ipsum dolor sit amet.",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
};

export const VideoTwoData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
  },
};

export const QuemSomosData = {
  sectionContent: {
    img1:quemSomos,
    title: "Quem \n Somos",
    titleMobile: "Quem Somos",
    imgMobile:quemSomos,
  },
};


export const AboutTwoData = {
  sectionContent: {
    logo: logoDefault,
    img1:professor1,
    img2: professor2,
    imgMobile: profMobile,
    title: "VENHA SOMAR COM QUEM\n FAZ A DIFERENÇA.",
    subTitle: "Lorem ipsum dolor sit amet.",
    text:
      "As inscrições para a OPMbr 2025-2026 serão abertas em março de 2025",
  },
  button: {
    label: "INSCRIÇÕES EM 03/2025",
    url: "#",
  },
  vetor:{
    vetor1: v1,
    vetor2: v2,
    vetor3: v3,
  }
};

export const NewsletterData = {
  sectionContent: {
    img1:livros,
    img2: professor2,
    lwb: leftWhiteBracket,
    rwb: rightWhiteBracket,
    title: "Patrocine a Olimpíada Brasileira dos Professores de Matemática do Ensino Médio no Brasil (OPMBR).",
  },
  button: {
    label: "APOIE",
    url: "#",
  },
};

export const FeatureTwoData = {
  sectionContent: {
    title: "Lorem ipsum",
    subTitle: "Lorem ipsum dolor",
    text:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius. ",
  },
  posts: [
    {
      title: "Lorem ipsum dolor sit",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero",
    },
    {
      title: "Lorem ipsum dolor sit",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero",
    },
    {
      title: "Lorem ipsum dolor sit",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero",
    },
  ],
  image: {
    text: "Lorem ipsum dolor sit amet \n consectetur adipiscing.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
